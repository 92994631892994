import { Box, Button, Grid, Paper, Typography, makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { Fragment, useState } from 'react';
// import Truncate from 'react-truncate';
import classNames from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CostUnit, displayCost } from '../../utilities/currencyFormatter';
import LogoSpinner from '../../common/logoSpinner.component';
import SelectionsCostBar from './selectionsCostBar.component';

import HhgImage from './images/moving-truck.jpg';
import TempLivingImage from './images/temporary-living.jpg';
import FinalMoveImage from './images/airplane-travel.jpg';
import HomeFindingImage from './images/home-finding.jpeg';
import HomePurchasingImage from './images/home-purchasing.jpg';
import HomeSaleImage from './images/home-sale.jpg';
import RentalImage from './images/rental-image.jpg';
import SpousalImage from './images/spousal-assistance.jpg';
import { setEstimations } from './estimator.actions';

const useStyles = makeStyles((theme) => ({
  button: {
    width: 100,
    marginRight: '5px'
  },
  longButton: {
    width: 125
  },
  moveGuideHeader:{
    height: '75px',
    borderBottom: 2,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.divider,
    color: '#7F7F7F',
    borderRadius: `${theme.spacing(1)} ${theme.spacing(1)} 0 0`,
  },
  progressBar: {
    width: '100%',
  },
  optionRow: {
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderTopColor: theme.palette.divider,
    minHeight: theme.spacing(3),
  },
  benefitImage: {
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    height: '60px',
  },
  benefitImageSkipped: {
    filter: 'grayscale(100%)',
  },
  optionQuantityText:{
    fontStyle: 'italic',
    fontSize: '0.6rem',
  },
  benefitListContainer: {
    overflow: 'auto',
  },
  benefitListWrapper: {
    overflow: 'auto',
  },
  loadingOverlay :{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 3,
    backgroundColor: 'rgba(0,0,0,0.3)', // controls black overlay opacity
    display: 'flex',
  },
  benefitSelected: {
    boxShadow: '0px 0px 12px 1px rgba(90,154,156,0.5)',
  },
}));

const benefits = [
    {
        name: 'Household Goods',
        secondaryText: 'Shipment of your household goods, vehicles, and pets.',
        imageUrl: HhgImage,
        id: 0,
        isSelected: false,
        options: [
            {
                description: 'Full pack, load, transport.',
                variable: 'miles',
                multiplier: 25
            },
            {
                description: 'Auto Shipment.',
                variable: 'miles',
                multiplier: 15
            },
            {
                description: 'Partial unpack.',
                cost: 100
            },
            {
                description: 'Normal appliance servicing.',
                cost: 100
            },
            {
                description: 'Crating.',
                cost: 100
            },
            {
                description: 'Storage.',
                cost: 500
            },
            {
                description: 'Insurance.',
                cost: 100
            },
            {
                description: 'Discard and donate.',
                cost: 0
            },
        ]
    },
    {
        name: 'Rental',
        secondaryText: 'Costs associated with a rental relocation.',
        imageUrl: RentalImage,
        id: 1,
        isSelected: false,
        isRenterOnly: true,
        options: [
            {
                description: 'Rental tour fees (assuming $995 per day).',
                cost: 995
            },
            {
                description: 'Lease cancellation.',
                cost: 1500
            },
        ]
    },
    {
        name: 'Temporary Living',
        secondaryText: 'Temporary housing in your new location.',
        imageUrl: TempLivingImage,
        id: 2,
        isSelected: false,
        options: [
            {
                description: 'Up to 60 days of temporary living.',
                cost: 6000
            },
            {
                description: '2 trips home per month.',
                cost: 1500
            },
            {
                description: 'Rental car for 10 days or until personal car is delivered.',
                cost: 300
            },
        ]
    },
    {
        name: 'Home Finding',
        secondaryText: 'Support for new home finding trips.',
        imageUrl: HomeFindingImage,
        id: 3,
        isSelected: false,
        options: [
            {
                description: 'Airfare / mileage / tolls.',
                variable: 'miles',
                multiplier: 12
            },
            {
                description: 'Lodging.',
                cost: 500
            },
            {
                description: 'Meal per diem.',
                cost: 300
            },
            {
                description: 'Car rental.',
                cost: 300
            },
            {
                description: 'Transportation to/from airport or airport parking if applicable.',
                cost: 100
            },
        ]
    },
    {
        name: 'Home Purchasing',
        secondaryText: 'Support for new home purchasing.',
        imageUrl: HomePurchasingImage,
        id: 4,
        isSelected: false,
        isOwnerOnly: true,
        options: [
            {
                description: 'Direct bill / reimbursement on 2% of mortgage.',
                cost: 3250
            },
            {
                description: 'Referrals to preferred agents.',
                cost: 0
            },
        ]
    },
    {
        name: 'Home Sale',
        secondaryText: 'Assistance with home sale.',
        imageUrl: HomeSaleImage,
        id: 5,
        isSelected: false,
        isOwnerOnly: true,
        options: [
            {
                description: 'Direct reimbursement - typically calculated as 7% of the sale price as closing costs.',
                cost: 2500
            },
        ]
    },
    {
        name: 'Spouse / Partner Assistance',
        secondaryText: 'Assistance for the spouse / partner in the new location.',
        imageUrl: SpousalImage,
        id: 6,
        isSelected: false,
        options: [
            {
                description: 'Courses, professional liscensing, resume support, etc.',
                cost: 500
            },
        ]
    },
    {
        name: 'Final Move',
        secondaryText: 'Costs associated with the final trip.',
        imageUrl: FinalMoveImage,
        id: 7,
        isSelected: false,
        options: [
            {
                description: 'Airfare, mileage, tolls, etc.',
                variable: 'miles',
                multiplier: 12
            },
            {
                description: 'Lodging - Assuming one night in old location, one night in new location and enroute.',
                cost: 400
            },
            {
                description: 'Meal per diem.',
                cost: 300
            },
            {
                description: 'Transportation to/from airport.',
                cost: 100
            },
        ]
    },
]

const BenefitSelections = (props) => {
  const { isUpdatingSelections } = props;
  const [expandedBenefitId, setExpandedBenefitId] = useState(null);
  const [savedBenefits, setSavedBenefits] = useState(props.estimateInfo && props.estimateInfo.savedBenefits ? props.estimateInfo.savedBenefits : []);

  const classes = useStyles();

  const onUpdateBenefitClick = (e, benefit, status) => {
    e.preventDefault();

    let tempSavedBenefits = [...savedBenefits];
    status ? tempSavedBenefits.push(benefit.id) : tempSavedBenefits.filter((tsb) => tsb.id !== benefit.id);
    setSavedBenefits(tempSavedBenefits);
  };

  const handleAccordionChange = (benefitId) => (event, isExpanded) => {
    setExpandedBenefitId(isExpanded ? benefitId : false);
  };

  const renderOption = (option, benIsSelected) => (
    <Grid item container direction="row" className={classes.optionRow}>
      <Grid item xs={8} container direction="column">
        <Grid item>
          <Typography variant="body2">
            {option.variable ? `${option.description} (${props.distance} Miles * $${option.multiplier})` : option.description}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={2} justify="flex-end" alignContent="center">
        <Typography variant="caption">{option.variable ? displayCost((Math.round(props.distance) * option.multiplier), CostUnit.CURRENCY) : displayCost(option.cost, CostUnit.CURRENCY)}</Typography>
      </Grid>
      <Grid item container xs={2} justify="flex-start" alignContent="center">
        {benIsSelected && 
          <Box pl={2}>
            <Typography variant="caption" color="secondary"><FontAwesomeIcon icon="check-circle" /> Confirmed</Typography>
          </Box>
        }
      </Grid>
    </Grid>
  );

  const renderBenefitRowAction = (benefit) => {
    if (savedBenefits.includes(benefit.id)) {
        return (
                <Button size="small" variant="outlined" disabled={false} color="primary" onClick={(e) => onUpdateBenefitClick(e, benefit, false)}>
                    Disable
                </Button>
        )
    } else {
        return (
                <Button size="small" variant="contained" disabled={false} color="primary" onClick={(e) => onUpdateBenefitClick(e, benefit, true)}>
                    Enable
                </Button>
        )
    }
  };

  const resetBenefits = () => {
    setSavedBenefits([]);
  };

  return (
    <Box position="relative">
        <Fragment>
          {isUpdatingSelections && (
            <Box className={classes.loadingOverlay}>
              <LogoSpinner size="large" />
            </Box>
          )}
          <Grid container direction="column">
            <Grid item>
              <Paper className={classes.moveGuideHeader}>
                <Box p={2.5}>
                  <Grid container direction="row">
                    <Grid item xs>
                      <Typography variant="h6">Benefit Selections</Typography>
                    </Grid>
                    <Grid item xs={2} container justify="center">
                      <Box mt={1} width="100%" textAlign="center">
                        <SelectionsCostBar savedBenefits={savedBenefits} benefits={benefits} distance={props.distance} />
                      </Box>
                    </Grid>
                    <Grid item xs container justify="flex-end" alignItems="flex-start">
                      <Box sx={{marginTop: '10px'}}>
                        <Button 
                          size="small"
                          color="secondary" 
                          className={classes.button} 
                          onClick={() => resetBenefits()}
                        >
                          Reset
                        </Button>
                        <Button variant="contained" 
                          size="small"
                          color="secondary" 
                          className={classes.button} 
                          onClick={() => props.exportEstimate(savedBenefits, benefits)}
                        >
                          Export
                        </Button>
                        <Button variant="contained" 
                          size="small"
                          color="secondary" 
                          className={classes.button} 
                          onClick={() => props.saveEstimate(savedBenefits)}
                        >
                          Save
                        </Button>
                        <Button variant="contained" 
                          size="small"
                          color="primary" 
                          className={classes.longButton} 
                          onClick={() => props.generateAuth(savedBenefits, benefits)}
                        >
                          Generate Auth
                        </Button>
                      </Box>
                      
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Grid>
            <Grid item className={classes.benefitListContainer}>
              <Grid container direction="column" wrap="nowrap">
                {
                  benefits.map((benefit) => {
                    const totalBenefitCost = benefit.options.reduce((total, o) => total + (o.variable ? (props.distance * o.multiplier) : o.cost), 0);

                    if (props.reloPolicy && props.reloPolicy === 'Renter' && benefit.isOwnerOnly) return null;

                    if (props.reloPolicy && props.reloPolicy === 'Homeowner' && benefit.isRenterOnly) return null;

                    return (
                      <Accordion 
                        key={benefit.id}
                        expanded={expandedBenefitId === benefit.id}
                        className={expandedBenefitId === benefit.id ? classes.benefitSelected : null}
                        onChange={handleAccordionChange(benefit.id)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          // style={{ cursor: !hasSelectedOptions && 'default' }} // inline to ensure override
                        >
                          <Grid container alignItems="center" spacing={1}>
                            <Grid item xs={1} style={{ backgroundImage: `url(${benefit.imageUrl})` }}
                              className={classNames(classes.benefitImage)}
                            />
                            <Grid item xs={11}>
                              <Box mr={0}>
                                <Grid container>
                                  <Grid item xs={9}>
                                    <Typography variant="subtitle1">
                                      {benefit.name}
                                    </Typography>
                                    <Box lineHeight="1">
                                      <Typography variant="caption">
                                        {benefit.secondaryText}
                                      </Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ paddingTop: '10px'}}>
                                        <Typography variant="subtitle1">{expandedBenefitId !== benefit.id && displayCost(totalBenefitCost, CostUnit.CURRENCY)}</Typography>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={1}>
                                    {benefit.isSelected && (
                                        <Box sx={{ paddingTop: '15px' }}>
                                            <FontAwesomeIcon icon="check-circle" />
                                        </Box>
                                    )}
                                  </Grid>
                                  <Grid item xs={1}>
                                    <Box sx={{ paddingTop: '10px'}}>
                                        {renderBenefitRowAction(benefit)}
                                    </Box>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        </AccordionSummary>
                        <AccordionDetails style={{ marginTop: 0 }}>
                          {
                            (benefit.options.length > 0) &&
                              <Grid container>
                                <Grid item xs={1} />
                                <Grid item xs={11}>
                                  <Box mr={4}>
                                    <Grid container direction="column" spacing={4}>
                                      {
                                        benefit.options.map((opt) => renderOption(opt, savedBenefits.includes(benefit.id)))
                                      }
                                    </Grid>
                                  </Box>
                                </Grid>
                              </Grid>
                          }
                        </AccordionDetails>
                      </Accordion>
                    );
                  })
                }
              </Grid>
            </Grid>
          </Grid>
        </Fragment> 
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    estimates: state.estimator.get('estimates'),
  };
};

export default 
  connect(mapStateToProps, {
    setEstimations
  })(BenefitSelections)
;
