import { combineReducers } from 'redux';
import admin from './modules/admin/admin.reducer';
import apiError from 'middleware/apiError.reducer';
import authorizations from './modules/authorizations/authorizations.reducer';
import benefit from './modules/benefits/benefit.reducer';
import contacts from './modules/contacts/contacts.reducer';
import dashboard from './modules/dashboard/dashboard.reducer';
import documents from './modules/documents/documents.reducer';
import exceptions from './modules/exceptions/exceptions.reducer';
import layout from './modules/layout/layout.reducer';
import login from './modules/login/login.reducer';
import policy from './modules/policies/policy.reducer';
import reporting from './modules/reporting/reporting.reducer';
import transferees from './modules/transferees/transferees.reducer';
import estimator from 'modules/estimator/estimator.reducer';

const rootReducer = combineReducers({
  layout,
  login,
  admin,
  transferees,
  authorizations,
  exceptions,
  contacts,
  reporting,
  dashboard,
  benefit,
  policy,
  documents,
  estimator,
  apiError,
});

export default rootReducer;
