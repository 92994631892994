import { connect } from 'react-redux';
import { getAuthorizationCustomFields, getClientFieldListValues, getClientSpecificFields } from '../authorizations.actions';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import CustomDatePicker from '../../../common/customDatePicker.component';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';

import { dateDiffDays } from '../../../utilities/dateFormatter';
import { detectIE } from '../../../utilities/browserUtils';
import {
  getSelectedClientInformation,
  getSelectedDivisions,
  getUserDivisions,
  userHasPermission,
  isAirBornClient,
  isKlaTaxClient, //Added via AB#869
  isBattelleEnergyAllianceClient,
  isSamsungClient, 
  isSolenisClient,
} from '../../../utilities/authUtils';
import { requiredField, validDate } from '../../../utilities/fieldValidation';

import { CurrencyFormat, IntegerFormat, TwoDecimalFormat } from '../../../common/numberFormatCustom.component';
import { isProposedEndDateRequired, removeLettersFromBusCode } from './utils';
import ContactForm from './contactForm.component';
import CustomSelect from '../../../common/customSelect.component';
import Typography from '@material-ui/core/Typography';
import { defaultFieldsBasedOnReloPolicy } from './customClientLogic';
import { CostUnit, displayCost } from 'utilities/currencyFormatter';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
//import Truncate from 'react-truncate';

const DIVISION_BUS_CODE_LIST = 'BC1';
const NONE = 'none';
let moveTypeIsRequired = false;

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  textFieldMedium: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 225,
    maxWidth: 400,
  },
  textFieldWide: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    minWidth: 275,
    maxWidth: 475,
  },
  menu: {
    width: 200,
  },
  paperSection: {
    width: '100%',
    padding: '1rem',
    marginBottom: '0.75rem',
  },
  gridButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
});

class EmployeeForm extends Component {
  constructor(props) {
    super(props);

    this.getBusCodes = this.getBusCodes.bind(this);
    this.getProvinces = this.getProvinces.bind(this);
    this.getDivisionSelectComponent = this.getDivisionSelectComponent.bind(this);
    this.divisionIdIsSelected = this.divisionIdIsSelected.bind(this);
    this.getFilteredBusCodes = this.getFilteredBusCodes.bind(this);
    this.getDivisionBusCode = this.getDivisionBusCode.bind(this);
  }

  getBusCodes(busCodeId) {
    const codes = this.props.formData.busCodes;
    let busCodeList = [];
    if (codes) {
      busCodeList = codes.filter((code) => code.BusCodeType === busCodeId);
    }
    return busCodeList;
  }

  getProvinces(ISO3Code) {
    if (this.props.states && this.props.states.length > 0) {
      return this.props.states.filter((s) => s.CountryCode === ISO3Code)
        .map((option) => {
          return {
            value: option.Code,
            label: option.Description,
          };
        });
    }
    return [];
  }

  handleDivisionChange = (field, divisionCodes) => (event) => {
    const { handleCustomFieldChange } = this.props;
    const value = event.target.value;
    if (value === '') {
      field.BusCode = null;
      field.BusCodeDesc = null;
      if (field.User1) delete field.User1;
      if (field.User2) delete field.User2;
      field.BusCodeKey = null;
      return handleCustomFieldChange(field);
    }

    const selectedField = divisionCodes.find((divisionCode) => divisionCode.BusCode === value);
    return handleCustomFieldChange(selectedField);
  };

  // need to handle division component separately from rest of custom fields
  getDivisionSelectComponent() {
    const { classes, isEdit, readonly } = this.props;
    const { CustomFields } = this.props.formData.selectedAuth;
    const clientId = getSelectedClientInformation().id || null;

    let divisionCustomField = CustomFields.find((f) => f.BusCodeList === DIVISION_BUS_CODE_LIST);
    if (!divisionCustomField) return null;

    const divisionBusCodes = this.getBusCodes(divisionCustomField.BusCodeList);
    let filteredDivisionCodes = divisionBusCodes;
    if(clientId !== '232' && clientId !== '274' && !isSamsungClient())
    {
      filteredDivisionCodes = this.getFilteredBusCodes(divisionBusCodes);
    }
    if (CustomFields && CustomFields.length > 0 && filteredDivisionCodes.length > 0) {
      return (
        <div>
          <TextField
            id={divisionCustomField.BusCode}
            select
            label={divisionCustomField.BusCodeLabel}
            className={classes.textFieldMedium}
            style={{ width: '100%' }}
            value={divisionCustomField.BusCode ? divisionCustomField.BusCode : isEdit ? '0' : ''}  //if this is null, then either set the value to 0 for top level, or '' for null option.
            required={divisionCustomField.IsRequired}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            disabled={readonly}
            onChange={this.handleDivisionChange(divisionCustomField, filteredDivisionCodes)}
          >
            {/* null option */}
            {!isEdit &&
              <MenuItem value="" />
            }

            {/* options */}
            {filteredDivisionCodes.map((divisionCode, i) => {
              return (
                <MenuItem key={divisionCode.BusCode} value={divisionCode.BusCode}>
                  {divisionCode.BusCodeDesc}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
      );
    } else {
      return null;
    }
  }

  // return whether a division is selected, based on busCodeKey
  divisionIdIsSelected(busCodeKey) {
    const selectedDivs = getSelectedDivisions();
    if (selectedDivs && selectedDivs.length > 0) {
      return selectedDivs.indexOf(busCodeKey) > -1;
    }
    return true;
  }

  // filter division bus code list by what is available in the token
  getFilteredBusCodes(busCodeList) {
    const clientId = getSelectedClientInformation().id || null;
    const availableDivs = getUserDivisions(clientId, this.props.clientDivisions);
    const topLevelDiv = availableDivs.find((ad) => ad.id === '0');
    // if user has top level (0th division), add a division option for top level
    if (topLevelDiv) {
      busCodeList.unshift({
        BusCodeKey: '0',
        BusCodeType: 'BC1',
        BusCode: '0',
        BusCodeDesc: topLevelDiv.description,
        SortCode: null,
        User1: topLevelDiv.description,
        User2: topLevelDiv.description,
      });
    }
    return busCodeList.filter((bc) => availableDivs.findIndex((ad) => {
      return (ad.id === bc.BusCodeKey.toString());
    }) > -1,
    ).sort((a, b) => a.BusCodeKey - b.BusCodeKey);
  }

  getDivisionBusCode() {
    const { CustomFields } = this.props.formData.selectedAuth;
    if (CustomFields && CustomFields.length > 0) {
      return CustomFields.find((cf) => cf.BusCodeList === DIVISION_BUS_CODE_LIST);
    }
    return null;
  }

  renderProposedEndDate(isIE) {
    const { classes, formData, handleFormChange, readonly } = this.props;
    const { MoveType, ProposedEndDate } = formData.selectedAuth;

    if (!isProposedEndDateRequired(MoveType)) {
      return null;
    }

    if (isIE) {
      return (
        <div style={{ marginLeft: '1rem' }}>
          <CustomDatePicker
            selected={ProposedEndDate}
            onChange={handleFormChange('ProposedEndDate')}
            label="Proposed End Date"
            required={true}
            disabled={readonly}
          />
        </div>
      );
    }

    return (
      <TextField
        id="endDate"
        label="Proposed End Date"
        helperText={ProposedEndDate ? validDate(ProposedEndDate) : null}
        type="date"
        style={{ width: '250px' }}
        onChange={handleFormChange('ProposedEndDate')}
        value={ProposedEndDate ? ProposedEndDate : ''}
        InputLabelProps={{
          shrink: true,
        }}
        className={classes.textField}
        required={true}
        disabled={readonly}
      />
    );
  }

  getSelectedPolicyBudget = (selfServicePolicies, selectedPolicyId) => {
    const selectedPolicy = selfServicePolicies.find((pol) => pol.clientPolicyId === selectedPolicyId);
    if (selectedPolicy) {
      return selectedPolicy.budget;
    }
  };

  getSelfServicePolicyMenuItems(selfServicePolicies, reloPolicy, selectedPolicyId) {
    const selectedPolicy = selfServicePolicies.find((pol) => pol.clientPolicyId === selectedPolicyId);
    const filteredPolicies = selfServicePolicies.filter(((ssPol) => {
      if (ssPol.moveTrackPolicies.length) {
        return ssPol.moveTrackPolicies.some((mtPol) => mtPol.name === reloPolicy);
      }
      return false;
    }));
    const menuItems = filteredPolicies.map((policy) => {
      return (
        <MenuItem key={policy.clientPolicyId} value={policy.clientPolicyId}>
          {policy.name}
        </MenuItem>
      );
    });
    menuItems.push(
      <MenuItem key="" value={NONE}>
        None
      </MenuItem>,
    );
    // if our selected policy was filtered out, add it as a disabled option
    if (!filteredPolicies.some((pol) => pol.clientPolicyId === selectedPolicyId)) {
      if (selectedPolicy) {
        menuItems.push(
          <MenuItem key={selectedPolicy.clientPolicyId} value={selectedPolicy.clientPolicyId} disabled>
            {selectedPolicy.name}
          </MenuItem>,
        );
      }
    }
    return menuItems;
  }

  defaultFieldsBasedOnReloPolicy(reloPolicy) {
    const clientId = getSelectedClientInformation().id;
    const clientSpecificFunction = defaultFieldsBasedOnReloPolicy[clientId];
    if (clientSpecificFunction) {
      clientSpecificFunction(reloPolicy, this.props.handleFormChange);
    }
  }

  render() {
    const {
      classes,
      countries,
      formData,
      handleFormChange,
      handleOnBlur,
      handleContactChange,
      handleCheckboxChange,
      handleCustomFieldChange,
      handleClientCustomFieldChange,
      isEdit,
      readonly,
      duplicateAuthHelperText
    } = this.props;

    const {
      FirstName,
      LastName,
      NickName,
      SpouseFirstName,
      SpouseLastName,
      OldAddress1,
      OldAddress2,
      OldState,
      OldCity,
      OldZip,
      OldCountry,
      NewAddress1,
      NewAddress2,
      NewState,
      NewCity,
      NewZip,
      NewCountry,
      VIP,
      ProposedEffectiveDate,
      ProposedEndDate,
      EmployeeType,
      ReloPolicy,
      SelfServicePolicyId,
      SelfServicePolicyBudget,
      MoveType,
      Ownership,
      ClientCustomFields,
      CustomFields,
    } = formData.selectedAuth;

    let shortTermAssignment = false;
    let longTermAssignment = false;
    let showDateStartEndWarning = false;
    if (ProposedEffectiveDate && ProposedEndDate) {
      const diff = dateDiffDays(new Date(ProposedEffectiveDate), new Date(ProposedEndDate));
      shortTermAssignment = diff < 365;
      longTermAssignment = diff >= 365;
      showDateStartEndWarning = diff <= 0;
    }

    const isIE = detectIE();
    // filter state options by selected country, default USA
    const oldStateOptions = this.getProvinces(OldCountry);
    const newStateOptions = this.getProvinces(NewCountry);

    let countryOptions = [];
    if (countries) {
      countryOptions = countries.map((country) => ({
        value: country.CountryCode,
        label: country.Description,
      }));
    }

    const empTypeOptions = formData.clientFieldValues.filter((item) => item.PageFieldId === 6);
    const ownerStatusOptions = formData.clientFieldValues.filter((item) => item.PageFieldId === 26);
    const reloPolicies = formData.reloPolicies || [];
    let selfServicePolicies = formData.selfServicePolicies || [];
    const moveTypes = formData.busCodes ? formData.busCodes.filter((item) => item.BusCodeType === 'MOVETYPE') : [];

    const divisionSelect = this.getDivisionSelectComponent();
    const divisionBusCode = this.getDivisionBusCode();

    let isSelectedDivisionFiltered = false;
    let isShowDivisionRequiredMessage = false;
    let divisionPolicies = reloPolicies;
    if (divisionBusCode) {
      let busCodeKey = divisionBusCode.BusCodeKey !== null ? divisionBusCode.BusCodeKey.toString() : null;
      if (busCodeKey === null && isEdit) busCodeKey = '0'; // treat null the same as 0-level
      isSelectedDivisionFiltered = busCodeKey === null ? false : !this.divisionIdIsSelected(busCodeKey);
      isShowDivisionRequiredMessage = busCodeKey === null && !isEdit;
    }

    //if client ID is 238 (MPC) or 242 (Speedway), don't require the moveType field
    if(getSelectedClientInformation().id !== '238' && getSelectedClientInformation().id !== '242') moveTypeIsRequired = true;

    //if client ID is 274 (Foxconn) or 317 (Samsung), filter Program Type by division selected
    if((getSelectedClientInformation().id === '274' || isSamsungClient()) && formData.busCodes && divisionBusCode)
    {
      divisionPolicies = [];
      let busCodeOptions = this.getBusCodes('BC1');
      let divs = busCodeOptions.filter((code) => code.BusCodeKey === divisionBusCode.BusCodeKey);
      let policies = divs[0] && divs[0].User7 ? divs[0].User7.split(';') : [""];
      policies.map((policy) => {
        // for every policy given for the selected division, we will grab each respective policy from reloPolicies
        let temp = reloPolicies.filter((code) => code.PolicyId === parseInt(policy));
        if (temp[0]) {
          divisionPolicies.push(temp[0]);
        } 
        return divisionPolicies;
      });

      if (divisionPolicies.length === 0) {
        divisionPolicies = reloPolicies;
      }
    }
    
    const hasSelfServicePermission = userHasPermission('selfService:policies', this.props.clientDivisions);
    const selfServicePolicyMenuItems = this.getSelfServicePolicyMenuItems(selfServicePolicies, ReloPolicy, SelfServicePolicyId);
    const allowEmptyDestination = userHasPermission('authorization:allowEmptyDestination', this.props.clientDivisions);

    const urlParams = new URLSearchParams(window.location.search);

    return (
      <form className={classes.container} noValidate autoComplete="off">
        {divisionSelect &&
          <Paper className={classes.paperSection}>
            <h3>Select Division</h3>
            {divisionSelect}
            {isSelectedDivisionFiltered &&
              <Grid container item style={{ marginTop: '1rem', paddingLeft: '1rem' }}>
                <div style={{ display: 'inline-flex' }}>
                  <InfoIcon />
                  <div style={{ lineHeight: '22px', marginLeft: '0.5rem', maxWidth: 550 }}>
                    You have selected a division that is currently filtered in the 'Change Divisions' menu
                  </div>
                </div>
              </Grid>
            }
            {isShowDivisionRequiredMessage &&
              <Grid container item style={{ marginTop: '1rem', paddingLeft: '1rem' }}>
                <div style={{ display: 'inline-flex' }}>
                  <InfoIcon />
                  <div style={{ lineHeight: '22px', marginLeft: '0.5rem', marginRight: '0.5rem', maxWidth: 550 }}>
                    Please select a division to create this authorization.
                  </div>
                </div>
              </Grid>
            }
          </Paper>
        }
        <Paper className={classes.paperSection}>
          <h3>Employee Information</h3>
          <Grid container item>
            <TextField
              id="FirstName"
              label="Transferee First Name"
              className={classes.textField}
              value={FirstName ? FirstName : ''}
              onChange={handleFormChange('FirstName')}
              margin="normal"
              required
              helperText={requiredField(FirstName)}
              disabled={readonly}
            />
            <TextField
              id="LastName"
              label="Transferee Last Name"
              className={classes.textField}
              value={LastName ? LastName : ''}
              onChange={handleFormChange('LastName')}
              onBlur={handleOnBlur('LastName')}
              margin="normal"
              required
              helperText={duplicateAuthHelperText !== undefined ? duplicateAuthHelperText : requiredField(LastName)}
              disabled={readonly}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={VIP}
                  onChange={handleCheckboxChange('VIP')}
                  value={VIP ? 'true' : 'false'}
                  disabled={readonly}
                />
              }
              label="VIP?"
            />
          </Grid>
          <Grid container item>
            <TextField
              id="NickName"
              label="Transferee Nickname"
              className={classes.textField}
              value={NickName ? NickName : ''}
              onChange={handleFormChange('NickName')}
              margin="normal"
              disabled={readonly}
            />
          </Grid>
          <Grid container item>
            <TextField
              id="SpouseFirstName"
              label="Spouse First Name"
              className={classes.textField}
              value={SpouseFirstName ? SpouseFirstName : ''}
              onChange={handleFormChange('SpouseFirstName')}
              margin="normal"
              disabled={readonly}
            />
            <TextField
              id="SpouseLastName"
              label="Spouse Last Name"
              className={classes.textField}
              value={SpouseLastName ? SpouseLastName : ''}
              onChange={handleFormChange('SpouseLastName')}
              margin="normal"
              disabled={readonly}
            />
          </Grid>

          <Grid container item style={{ marginBottom: '1rem' }}>
            <TextField
              id="EmployeeType"
              select
              label="Employee Type"
              className={classes.textField}
              value={EmployeeType ? EmployeeType : ''}
              onChange={handleFormChange('EmployeeType')}
              margin="normal"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              required
              disabled={readonly}
            >
              {empTypeOptions.map((empType, i) => {
                return (<MenuItem key={i} value={empType.FieldListValue}>
                  {empType.FieldListDescription}
                </MenuItem>);
              })}
            </TextField>
          </Grid>
        </Paper>

        <Paper className={classes.paperSection}>
          <h3>Policy Information</h3>
          <Grid container item style={{ marginBottom: '1rem' }}>
            <TextField
              id="ReloPolicy"
              select
              label="Program Type"
              className={classes.textField}
              style={{ width: '250px' }}
              value={ReloPolicy ? ReloPolicy : ''}
              onChange={async (event) => {
                await handleFormChange('ReloPolicy')(event);
                await handleFormChange('SelfServicePolicyId')('');
                handleFormChange('SelfServicePolicyBudget')('');
                this.defaultFieldsBasedOnReloPolicy(event.target.value);
              }}
              margin="normal"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              required
              disabled={readonly}
            >
              {divisionPolicies && divisionPolicies.map((policy, i) => {
                return (<MenuItem key={i} value={policy.PolicyCode}>
                  {policy.PolicyCode}
                </MenuItem>);
              })}
            </TextField>
          </Grid>

          {hasSelfServicePermission && selfServicePolicyMenuItems.length > 1 &&
            <Grid container spacing={0} style={{ marginBottom: '1rem', marginLeft: '1rem' }}>
              <Grid item>
                <Typography variant="body2">
                  Selecting a Self Service Policy will enable your employee to use the TRC planning tool to understand how to utilize their
                  relocation budget and get the most services they need to make their experience successful.
                </Typography>
              </Grid>
              <Grid item>
                <TextField
                  id="SelfServicePolicy"
                  select
                  label="Self Service Policy"
                  className={classes.textField}
                  style={{ width: '250px' }}
                  value={SelfServicePolicyId || NONE}
                  onChange={async (event) => {
                    if (event.target.value === NONE) {
                      await handleFormChange('SelfServicePolicyId')('');
                    } else {
                      await handleFormChange('SelfServicePolicyId')(event);
                    }
                    handleFormChange('SelfServicePolicyBudget')(this.getSelectedPolicyBudget(selfServicePolicies, event.target.value));
                  }}
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  disabled={readonly || !ReloPolicy}
                >
                  {selfServicePolicyMenuItems}
                </TextField>

                <TextField
                  id="budget"
                  label="Budget"
                  className={classes.textField}
                  value={SelfServicePolicyBudget != null ? SelfServicePolicyBudget : ''}
                  InputProps={{ inputComponent: TwoDecimalFormat }}
                  inputProps={{ emitAsEvent: true }}
                  onChange={handleFormChange('SelfServicePolicyBudget')}
                  margin="normal"
                  disabled={readonly || !SelfServicePolicyId}
                />
              </Grid>
            </Grid>
          }

          <Grid container item style={{ marginBottom: '1.5rem' }}>
            <TextField
              id="moveType"
              select
              label="Move Type"
              className={classes.textField}
              style={{ width: '250px' }}
              value={MoveType ? MoveType : ''}
              onChange={handleFormChange('MoveType')}
              margin="normal"
              SelectProps={{
                MenuProps: {
                  className: classes.menu,
                },
              }}
              required = {moveTypeIsRequired}
              disabled={readonly}
            >
              {moveTypes.map((type, i) => {
                return (<MenuItem key={type.BusCodeKey} value={type.BusCode}>
                  {type.BusCodeDesc}
                </MenuItem>);
              })}
            </TextField>
            {isIE &&
              <div style={{ marginLeft: '0.5rem' }}>
                <CustomDatePicker
                  selected={ProposedEffectiveDate}
                  onChange={handleFormChange('ProposedEffectiveDate')}
                  label="Effective Date"
                  validator={(isAirBornClient() === true) ? requiredField : null}
                  disabled={readonly}
                  required={ (isAirBornClient() === true) ? true : false }
                />
              </div>
            }
            {!isIE && 
              <TextField
                id="startDate"
                label="Effective Date"
                validator={(isAirBornClient() === true) ? requiredField : null}
                helperText={ProposedEffectiveDate ? validDate(ProposedEffectiveDate) : null}
                type="date"
                style={{ width: '250px' }}
                onChange={handleFormChange('ProposedEffectiveDate')}
                value={ProposedEffectiveDate ? ProposedEffectiveDate : ''}
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                className={classes.textField}
                disabled={readonly}
                required={ (isAirBornClient() === true) ? true : false }
              />
            }
            {this.renderProposedEndDate()}
          </Grid>
          <Grid container item style={{ marginBottom: '1rem', paddingLeft: '1rem' }}>
            {showDateStartEndWarning && <div style={{ display: 'inline-flex', width: '100%', marginBottom: '0.5rem' }}><WarningIcon /><div style={{ lineHeight: '22px', marginLeft: '0.5rem' }}>End Date Must Be After Start Date</div></div>}
            {longTermAssignment && <div style={{ display: 'inline-flex', width: '100%' }}><InfoIcon /><div style={{ lineHeight: '22px', marginLeft: '0.5rem' }}>Long Term Assignment (Duration &gt; 1 year)</div></div>}
            {shortTermAssignment && <div style={{ display: 'inline-flex', width: '100%' }}><InfoIcon /><div style={{ lineHeight: '22px', marginLeft: '0.5rem' }}>Short Term Assignment (Duration &lt; 1 year)</div></div>}
          </Grid>
        </Paper>

        {isKlaTaxClient() !== true && //isKlaTaxClient() logic added via AB#869
          <Paper className={classes.paperSection}>
            <h3>Departure Information</h3>
            <Grid container item>
              <TextField
                id="OldAddress1"
                label="Address Line 1"
                className={classes.textField}
                value={OldAddress1 ? OldAddress1 : ''}
                onChange={handleFormChange('OldAddress1')}
                margin="normal"
                helperText={requiredField(OldAddress1)}
                required
                disabled={readonly}
              />
              <TextField
                id="OldAddress2"
                label="Address Line 2"
                className={classes.textField}
                value={OldAddress2 ? OldAddress2 : ''}
                onChange={handleFormChange('OldAddress2')}
                margin="normal"
                disabled={readonly}
              />
              <TextField
                id="OldCity"
                label="City"
                className={classes.textField}
                value={OldCity ? OldCity : ''}
                onChange={handleFormChange('OldCity')}
                margin="normal"
                helperText={requiredField(OldCity)}
                required
                disabled={readonly}
              />
            </Grid>

            <Grid container item>
              <CustomSelect
                options={countryOptions}
                placeholder="Country *"
                validator={requiredField}
                onChange={handleFormChange('OldCountry')}
                value={OldCountry ? OldCountry : ''}
                disabled={readonly}
              />

              <CustomSelect
                options={oldStateOptions}
                placeholder={oldStateOptions.length > 0 && OldCountry === 'USA' ? 'State/Province *' : 'State/Province'}
                validator={oldStateOptions.length > 0 && OldCountry === 'USA' ? requiredField : null}
                onChange={handleFormChange('OldState')}
                value={OldState ? OldState : ''}
                disabled={readonly}
              />

              <TextField
                id="OldZip"
                label="Zip"
                className={classes.textField}
                value={OldZip ? OldZip : ''}
                onChange={handleFormChange('OldZip')}
                margin="normal"
                disabled={readonly}
              // helperText={requiredField(OldZip)}
              />

            </Grid>

            <Grid container item style={{ marginBottom: '1rem' }}>
              {ownerStatusOptions &&
                <TextField
                  id="Ownership"
                  select
                  label="Ownership"
                  className={classes.textField}
                  value={Ownership ? Ownership : ''}
                  onChange={handleFormChange('Ownership')}
                  margin="normal"
                  SelectProps={{
                    MenuProps: {
                      className: classes.menu,
                    },
                  }}
                  disabled={readonly}
                >
                  {console.warn(ownerStatusOptions)}
                  {ownerStatusOptions.map((status, i) => {
                    return (<MenuItem key={i} value={status.FieldListValue}>
                      {status.FieldListDescription}
                    </MenuItem>);
                  },
                  )}
                </TextField>
              }
            </Grid>
          </Paper>
        }
        
        {isKlaTaxClient() !== true && //isKlaTaxClient() logic added via AB#869
          <Paper className={classes.paperSection}>
            <h3>Destination Information</h3>
            <Grid container item>
              <TextField
                id="NewAddress1"
                label="Address Line 1"
                className={classes.textField}
                value={NewAddress1 ? NewAddress1 : ''}
                onChange={handleFormChange('NewAddress1')}
                margin="normal"
                disabled={readonly}
              />
              <TextField
                id="NewAddress2"
                label="Address Line 2"
                className={classes.textField}
                value={NewAddress2 ? NewAddress2 : ''}
                onChange={handleFormChange('NewAddress2')}
                margin="normal"
                disabled={readonly}
              />
              <TextField
                id="NewCity"
                label="City"
                className={classes.textField}
                value={NewCity ? NewCity : ''}
                onChange={handleFormChange('NewCity')}
                margin="normal"
                required={!allowEmptyDestination}
                helperText={!allowEmptyDestination && requiredField(NewCity)}
                disabled={readonly}
              />
            </Grid>

            <Grid container item style={{ marginBottom: '1rem' }}>
              <CustomSelect options={countryOptions}
                placeholder="Country *"
                validator={requiredField}
                onChange={handleFormChange('NewCountry')}
                value={NewCountry ? NewCountry : ''}
                disabled={readonly}
              />

              <CustomSelect options={newStateOptions}
                placeholder={(!allowEmptyDestination && newStateOptions.length > 0 && NewCountry === 'USA') ? 'State/Province *' : 'State/Province'}
                validator={(!allowEmptyDestination && newStateOptions.length > 0 && NewCountry === 'USA') ? requiredField : null}
                onChange={handleFormChange('NewState')}
                value={NewState ? NewState : ''}
                disabled={readonly}
              />

              <TextField
                id="NewZip"
                label="Zip"
                className={classes.textField}
                value={NewZip ? NewZip : ''}
                onChange={handleFormChange('NewZip')}
                margin="normal"
                disabled={readonly}
              />
            </Grid>

          </Paper>
        }
        
        {isKlaTaxClient() !== true && //isKlaTaxClient() logic added via AB#869
          <ContactForm formData={formData} countries={countries} handleContactChange={handleContactChange} readonly={readonly} />
        }

        {(ClientCustomFields.length > 0 || CustomFields.length > 0) &&
          <Paper className={classes.paperSection}>
            <h3>Employee Job Information</h3>
            <Grid container item>
              {ClientCustomFields &&
                ClientCustomFields.map((field, i) => {

                  if ((field.FieldName === 'InternStartDate' || field.FieldName === 'InternEndDate') && isSamsungClient() && MoveType !== 'INTERN') return null;

                  if (field.TextFormat === 'Date' && ( (field.disabled === undefined || field.disabled === false) || (field.disabled === true && field.FieldValue !== null && field.FieldName !== 'RemoveByDate') )) {
                    const fieldValue = field.FieldValue;


                    // format .net date strings
                    // example input: Apr 24 2018 12:00AM
                    const dotNetFormat = moment(fieldValue, 'MMM  D YYYY hh:mmA', true).isValid() ||
                      moment(fieldValue, 'MMM DD YYYY hh:mmA', true).isValid();

                    const formattedDate = dotNetFormat ? moment(fieldValue, 'MMM DD YYYY hh:mmA').format('YYYY-MM-DD').toString() : field.FieldValue;

                    // set updated value in field
                    field.FieldValue = formattedDate;

                    return (
                      <TextField
                        key={i}
                        id={`cfDate${  i}`}
                        label={field.FieldLabel}
                        placeholder={isIE ? 'yyyy-mm-dd' : null}
                        helperText={formattedDate ? validDate(formattedDate) : null}
                        type={isIE ? null : 'date'}
                        style={{ marginTop: '1rem' }}
                        onChange={handleClientCustomFieldChange(field)}
                        value={formattedDate ? formattedDate : ''}
                        className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        required={field.FieldRequired}
                        disabled={readonly}
                      />
                    );
                  } else if (field.TextFormat === 'Number') {
                    return (
                      <TextField
                        className={classes.textField}
                        id={`cfNumber${  i}`}
                        key={`cfNumber${  i}`}
                        label={field.FieldLabel}
                        required={field.FieldRequired}
                        margin="normal"
                        onChange={handleClientCustomFieldChange(field)}
                        InputProps={{ inputComponent: IntegerFormat }}
                        value={field.FieldValue ? field.FieldValue : ''}
                        disabled={readonly}
                      />
                    );
                  } else if (field.TextFormat === 'List') {
                    if(field.UserGroup === 'Division')
                    {
                      if(field.FieldRequired === true)
                      {
                        field.FieldRequired = false;
                      }
                      let fieldBusCode = removeLettersFromBusCode(field.FieldName);
                      let currentBusCode = CustomFields[0].BusCodeKey;
                      if(currentBusCode && fieldBusCode === currentBusCode.toString())
                      {
                        field.FieldRequired = true;
                        const listValues = field.FieldList.map((field) => {
                          if (field.includes('%')) {
                            if (isBattelleEnergyAllianceClient() === true && field.includes('ChargeNum3')) { //Added via AB#869 for BEA Client
                              return { value: field, label: 'ASSIGNMENT' }; //Added via AB#869 for BEA Client
                            } else {
                              return { value: field, label: 'Other' }; //The default behavior for all other Clients
                            }
                          }
                          else if (field.includes("|")) {
                            let fieldValue = field.split("|")[1].toString();
                            return { value: fieldValue, label: fieldValue };
                          }
                          else
                            return { value: field, label: field };
                        });
                        if (field.FieldList.length > 3) {
                          // const mappedFieldList = field.FieldList.map((field) => ({ value: field, label: field }));
                          return (
                            <CustomSelect
                              id={`cfList${  i}`}
                              key={`cfList${  i}`}
                              options={listValues}
                              placeholder={field.FieldLabel}
                              validator={field.FieldRequired ? requiredField : null}
                              onChange={handleClientCustomFieldChange(field)}
                              value={field.FieldValue ? field.FieldValue : ''}
                              disabled={readonly || field.disabled}
                            />
                          );
                        }
                        if (field.FieldList.length === 1) {
                          field.FieldValue = (field.FieldList[0].includes("|")) ? field.FieldList[0].split("|")[1] : field.FieldList[0];
                        }
                        return (
                          <TextField
                            id={`cfList${  i}`}
                            select
                            label={field.FieldLabel}
                            className={classes.textField}
                            value={field.FieldValue ? field.FieldValue : ''}
                            onChange={handleClientCustomFieldChange(field)}
                            margin="normal"
                            key={`cfList${  i}`}
                            required={field.FieldRequired}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            disabled={readonly || field.disabled}
                          >
                            <MenuItem value="" />
                            {listValues.map((item, i) => {
                              return <MenuItem key={i} value={item.value}>{item.label}</MenuItem>;
                            })}
                          </TextField>
                        );
                      }
                      else
                      {
                        return (
                          <div></div>
                        );
                      }
                    }
                    else {
                      const listValues = field.FieldList.map((field) => {
                        if (field.includes('%')) {
                          if (isBattelleEnergyAllianceClient() === true && field.includes('ChargeNum3')) { //Added via AB#869 for BEA Client
                            return { value: field, label: 'ASSIGNMENT' }; //Added via AB#869 for BEA Client
                          } else {
                            return { value: field, label: 'Other' }; //The default behavior for all other Clients
                          }
                        }
                        else if (field.includes("|")) {
                          let fieldValue = field.split("|")[1].toString();
                          return { value: fieldValue, label: fieldValue };
                        }
                        else
                          return { value: field, label: field };
                      });
                      if (field.FieldList.length > 3) {
                        // const mappedFieldList = field.FieldList.map((field) => ({ value: field, label: field }));
                        return (
                          <CustomSelect
                            id={`cfList${  i}`}
                            key={`cfList${  i}`}
                            options={listValues}
                            placeholder={field.FieldLabel}
                            validator={field.FieldRequired ? requiredField : null}
                            onChange={handleClientCustomFieldChange(field)}
                            value={field.FieldValue ? field.FieldValue : ''}
                            disabled={readonly || field.disabled}
                          />
                        );
                      }
                      if (field.FieldList.length === 1) {
                        field.FieldValue = (field.FieldList[0].includes("|")) ? field.FieldList[0].split("|")[1] : field.FieldList[0];
                      }
                      return (
                        <TextField
                          id={`cfList${  i}`}
                          select
                          label={field.FieldLabel}
                          className={classes.textField}
                          value={field.FieldValue ? field.FieldValue : ''}
                          onChange={handleClientCustomFieldChange(field)}
                          margin="normal"
                          key={`cfList${  i}`}
                          required={field.FieldRequired}
                          SelectProps={{
                            MenuProps: {
                              className: classes.menu,
                            },
                          }}
                          disabled={readonly || field.disabled}
                        >
                          <MenuItem value="" />
                          {listValues.map((item, i) => {
                            return <MenuItem key={i} value={item.value}>{item.label}</MenuItem>;
                          })}
                        </TextField>
                      );
                    }
                  } else if (field.TextFormat === 'Currency') {
                    return (
                      <TextField
                        className={classes.textField}
                        id={`cfCurrency${  i}`}
                        key={`cfCurrency${  i}`}
                        label={field.FieldLabel}
                        margin="normal"
                        onChange={handleClientCustomFieldChange(field)}
                        InputProps={{ inputComponent: CurrencyFormat }}
                        required={field.FieldRequired}
                        value={field.FieldValue ? field.FieldValue : ''}
                        disabled={readonly || field.disabled}
                      />
                    );
                  } else {
                    const maxFieldLength = (isSolenisClient() && field.FieldName === 'SolenisCoCode') ? 4 : null;
                    if ( (field.disabled === undefined || field.disabled === false) || (field.disabled === true && field.FieldValue !== null && field.FieldName !== 'RemoveByDate') ) {
                      return (
                        <TextField
                          key={i}
                          label={field.FieldLabel}
                          className={classes.textField}
                          value={field.FieldValue ? field.FieldValue : ''}
                          onChange={handleClientCustomFieldChange(field)}
                          inputProps={{ maxLength: maxFieldLength }}
                          margin="normal"
                          required={field.FieldRequired}
                          disabled={readonly || field.disabled}
                        />
                      );
                    } else {
                      return null;
                    }
                  }
                })
              }
            </Grid>

            <Grid container item>
              {CustomFields &&
                CustomFields.map((field, i) => {
                  // exclude division from this list
                  if (field.BusCodeList !== DIVISION_BUS_CODE_LIST) {
                    const busCodeOptions = this.getBusCodes(field.BusCodeList);
                    if (busCodeOptions.length > 0) {
                      return (
                        <div>
                          <TextField
                            id={field.BusCode}
                            key={i}
                            select
                            label={field.BusCodeLabel}
                            className={classes.textFieldMedium}
                            value={field.BusCode ? field.BusCode : ''}
                            margin="normal"
                            required={field.IsRequired}
                            SelectProps={{
                              MenuProps: {
                                className: classes.menu,
                              },
                            }}
                            disabled={readonly || field.disabled}
                            onChange={(event) => {
                              const { value } = event.target;
                              if (value) {
                                const busCode = busCodeOptions.find((instance) => instance.BusCode === value);
                                return handleCustomFieldChange(busCode);
                              } else {
                                field.BusCode = null;
                                field.BusCodeDesc = null;
                                if (field.User1) delete field.User1;
                                if (field.User2) delete field.User2;
                                field.BusCodeKey = null;
                                return handleCustomFieldChange(field);
                              }
                            }}
                          >
                            <MenuItem value="" />
                            {busCodeOptions.map((busCodeOption) => (
                              <MenuItem key={busCodeOption.BusCode} value={busCodeOption.BusCode}>
                                {busCodeOption.BusCode}
                              </MenuItem>
                            ))}
                          </TextField>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return null;
                  }
                })
              }
            </Grid>
          </Paper>
        }

         {/* Add Estimate Section Here */}
         {urlParams.get('estimateId') && urlParams.get('estimateTotal') &&
          <Paper className={classes.paperSection}>
            <h3>Estimate Information</h3>
            <Typography>Estimated Cost of Move: <b>{displayCost(parseFloat(urlParams.get('estimateTotal')), CostUnit.CURRENCY)}</b></Typography>
            <Box sx={{paddingTop: '10px'}}>
              <Typography>This total was calculated using Estimate ID {urlParams.get('estimateId')}. <Link to={`/estimates/${urlParams.get('estimateId')}`}>View full estimate.</Link></Typography>
            </Box>
          </Paper>
        }

      </form >
    );
  }
}

EmployeeForm.propTypes = {

};

const mapStateToProps = (state) => {
  return {
    countries: state.authorizations.get('countries'),
    states: state.authorizations.get('states'),
    clientDivisions: state.layout.get('clientDivisions'),
  };
};

EmployeeForm = withStyles(styles)(EmployeeForm);

export default connect(mapStateToProps, {
  getAuthorizationCustomFields,
  getClientFieldListValues,
  getClientSpecificFields,
})(EmployeeForm);

