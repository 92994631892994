  /* eslint-disable array-callback-return */
  /* eslint-disable react-hooks/exhaustive-deps */
  import {
    // TOAST_MESSAGE_SEVERITY_ERROR,
    setPageTitle,
    showToast,
  } from '../layout/layout.actions';
  import { connect } from 'react-redux';
  import { withRouter } from 'react-router-dom';
  import React, { useEffect, useState } from 'react';
  
  import { Box, Checkbox, FormControlLabel, Grid, makeStyles, MenuItem, TextField, Typography } from '@material-ui/core';
import GooglePlacesAutocompleteComponent from 'common/form/googlePlacesAutocomplete.component';
import BenefitSelections from './benefitSelections.component';
import { getDistance } from 'utilities/googleMapsClient';
import { setEstimations } from './estimator.actions';
import { COUNTRY_CODES } from 'utilities/countryCodes';

  const useStyles = makeStyles((theme) => ({
    textField: {
       maxWidth: '200px',
    },
  }));
  
  const CostEstimatorPage = (props) => {  
    const estimateId = props.match.params.estimateId ? parseInt(props.match.params.estimateId) : null;
    const estimateInfo = estimateId ? props.estimates.find((e) => e.estimateId === estimateId) : null;

    const [costEstimate, setCostEstimate] = useState(estimateInfo ? estimateInfo.costEstimate : '');
    const [reloPolicy, setReloPolicy] = useState(estimateInfo ? estimateInfo.reloPolicy : '');
    const [hasSpouse, setHasSpouse] = useState(estimateInfo ? estimateInfo.hasSpouse : false);
    const [youngChildren, setYoungChildren] = useState(estimateInfo ? estimateInfo.youngChildren : '');
    const [oldChildren, setOldChildren] = useState(estimateInfo ? estimateInfo.oldChildren : '');
    const [accompanyingAdults, setAccompanyingAdults] = useState(estimateInfo ? estimateInfo.accompanyingAdults : '');
    const [origin, setOrigin] = useState(estimateInfo ? estimateInfo.origin : '');
    const [destination, setDestination] = useState(estimateInfo ? estimateInfo.destination : '');
    const [transfereeName, setTransfereeName] = useState(estimateInfo ? estimateInfo.transfereeName : '');
    const [distance, setDistance] = useState(0);
    const [isExecutive, setIsExecutive] = useState(estimateInfo ? estimateInfo.isExecutive : false);

    const classes = useStyles();

    const saveEstimate = (savedBenefits) => {
        let tempEstimateList = [...props.estimates];

        let estimateBody = {
            costEstimate: costEstimate,
            reloPolicy: reloPolicy,
            hasSpouse: hasSpouse,
            youngChildren: youngChildren,
            oldChildren: oldChildren,
            accompanyingAdults: accompanyingAdults,
            origin: origin,
            destination: destination,
            transfereeName: transfereeName,
            distance: distance,
            isExecutive: isExecutive,
            savedBenefits: savedBenefits,
        }
    
        if (!estimateInfo) {
          estimateBody.estimateId = Math.floor(Math.random() * 100);
          tempEstimateList.unshift(estimateBody);
          props.setEstimations(tempEstimateList);
        } else {
          estimateBody.estimateId = estimateId;
          let tempEstimateIndex = tempEstimateList.findIndex((te) => te.estimateId === estimateId);
          tempEstimateList[tempEstimateIndex] = estimateBody;
          props.setEstimations(tempEstimateList);
        }

        props.history.push(`/estimates`);
    }

    const exportEstimate = (savedBenefits, allBenefits) => {
        var content = 'Estimate Id, Transferee Name, Cost Estimate Policy, Relo Policy, Origin Location, Destination Location, Move Miles,\r\n';
        content = content + `${estimateId},${transfereeName},${costEstimate},${reloPolicy},${origin.formattedAddress ? origin.formattedAddress.split(',').join('') : ''},${destination.formattedAddress ? destination.formattedAddress.split(',').join('') : ''},${distance},\r\n\r\n`;

        content = content + 'Benefit Id, Benefit Name, Description, Total Cost, Option 1, Option 1 Cost, Option 2, Option 2 Cost, Option 3, Option 3 Cost, Option 4, Option 4 Cost, Option 5, Option 5 Cost, Option 6, Option 6 Cost, Option 7, Option 7 Cost, Option 8, Option 8 Cost,\r\n';
        allBenefits.map((b) => {
            if (savedBenefits.includes(b.id)) {
                const totalBenefitCost = b.options.reduce((total, o) => total + (o.variable ? (distance * o.multiplier) : o.cost), 0);
                content = content + `${b.id},${b.name},${b.secondaryText.split(',').join('')},${totalBenefitCost},`;

                b.options.map((bo) => {
                    content = content + `${bo.description.split(',').join('')},${bo.variable ? (distance * bo.multiplier) : bo.cost},`;
                });

                content = content + '\r\n';
            }
        })
    
        var blob = new Blob([content], { type: 'text/csv;charset=utf-8;' });
        var url = URL.createObjectURL(blob);

        var pom = document.createElement('a');
        pom.href = url;
        pom.setAttribute('download', `Estimate-${estimateId}.csv`);
        pom.click();
    }

    const generateAuth = (savedBenefits, benefits) => {
        let total = 0;
        benefits.filter((b) => savedBenefits.includes(b.id)).map((benefit) => {
            benefit.options.map((opt) => {
                if (opt.variable) {
                    total = total + (distance * opt.multiplier);
                } else {
                    total = total + opt.cost;
                }
            })
        });

        props.history.push(`/authorizations/new?firstName=${transfereeName.split(' ')[0]}&lastName=${transfereeName.split(' ')[1]}&oldCountry=${COUNTRY_CODES.find((cc) => cc['alpha-2'] === origin.country)['alpha-3']}&oldState=${origin.state}&oldCity=${origin.city}
            &newCountry=${COUNTRY_CODES.find((cc) => cc['alpha-2'] === destination.country)['alpha-3']}&newState=${destination.state}&newCity=${destination.city}&ownership=${reloPolicy}&estimateId=${estimateId}&estimateTotal=${total}`);
    }

    useEffect(() => {
        (async () => {
            props.setPageTitle('Cost Estimator');

            if (origin && origin.formattedAddress && destination && destination.formattedAddress) {
                let newDistance = await getDistance(origin, destination);
                setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
            }
        })();
    }, []);

    const today = new Date();

    return (
        <div>
            <Box sx={{ position: 'fixed', zIndex: 4, backgroundColor: 'white', padding: '10px 0px 10px 10px' }}>
                <Box>
                    <Grid container direction="row" >
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="name"
                                    className={classes.textField}
                                    label="Transferee Name"
                                    value={transfereeName}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setTransfereeName(e.target.value)}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="costEstimate"
                                    select
                                    label="Cost Estimate Policy"
                                    className={classes.textField}
                                    onChange={(e) => setCostEstimate(e.target.value)}
                                    value={costEstimate}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value={'tierOne'} key={0}>Tier One</MenuItem>
                                    <MenuItem value={'tierTwo'} key={1}>Tier Two</MenuItem>
                                    <MenuItem value={'custom'} key={2}>Custom</MenuItem>
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="costEstimate"
                                    select
                                    label="Relocation Policy"
                                    className={classes.textField}
                                    onChange={(e) => setReloPolicy(e.target.value)}
                                    value={reloPolicy}
                                    fullWidth={true}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value={'Homeowner'} key={0}>Homeowner</MenuItem>
                                    <MenuItem value={'Renter'} key={1}>Renter</MenuItem>
                                </TextField>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ maxWidth: '200px' }}>
                                <GooglePlacesAutocompleteComponent
                                    initialValue={origin}
                                    value={origin.formattedAddress ? origin.formattedAddress : origin}
                                    label={'Origin Location'}
                                    onSelect={async (event, value) => {
                                        setOrigin(value);

                                        if (value && destination.length !== 0) {
                                            // Distance is returned in meters so we need to divide by 1609 to get miles.
                                            let newDistance = await getDistance(value, destination);
                                            setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
                                        }
                                    }}
                                    onInputEdit={async (event) => {
                                        setOrigin(event);
                                    }}
                                    onInputClear={(event) => {
                                        setOrigin('');
                                    }}
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // disabled={disabled}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ maxWidth: '200px' }}>
                                <GooglePlacesAutocompleteComponent
                                    initialValue={destination}
                                    value={destination.formattedAddress ?  destination.formattedAddress : destination}
                                    label={'Destination Location'}
                                    onSelect={async (event, value) => {
                                        setDestination(value);
                                        if (value && origin.length !== 0) {
                                            // Distance is returned in meters so we need to divide by 1609 to get miles.
                                            let newDistance = await getDistance(origin, value);
                                            setDistance(newDistance.value ? parseFloat(newDistance.value / 1609).toFixed(2) : 0);
                                        }
                                    }}
                                    onInputEdit={(event) => setDestination(event)}
                                    onInputClear={(event) => {
                                        setDestination('');
                                    }}
                                    size="small"
                                    // onFocus={onFocus}
                                    // onBlur={onBlur}
                                    // disabled={disabled}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '5px' }}>
                                <Typography color="primary">{estimateId ? `Cost Estimate ${estimateId}` : 'New Cost Estimate'}</Typography>
                                <Typography color="secondary">Move Miles: {distance}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '0px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={isExecutive}
                                            onChange={(e) => setIsExecutive(!isExecutive)}
                                            size="small"
                                        />
                                    }
                                    label={'Is Executive'}
                                />
                            </Box>
                            <Box sx={{ paddingTop: '0px' }}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hasSpouse}
                                            onChange={(e) => setHasSpouse(!hasSpouse)}
                                            size="small"
                                        />
                                    }
                                    label={'Traveling with SO'}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="youngChildren"
                                    className={classes.textField}
                                    label="# Children under 12"
                                    value={youngChildren}
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => setYoungChildren(e.target.value)}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="oldChildren"
                                    className={classes.textField}
                                    label="# Children under 18"
                                    value={oldChildren}
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => setOldChildren(e.target.value)}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="accompanyingAdults"
                                    className={classes.textField}
                                    label="# Accompanying Adults"
                                    value={accompanyingAdults}
                                    variant="outlined"
                                    type="number"
                                    onChange={(e) => setAccompanyingAdults(e.target.value)}
                                    size="small"
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="dateCreated"
                                    label="Date Created"
                                    type="date"
                                    // onChange={(e) => console.log(e.target.value)}
                                    value={`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() <= 9 ? '0' + today.getDate() : today.getDate()}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    placeholder={'yyyy-mm-dd'}
                                    className={classes.textField}
                                    disabled={true}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <Box sx={{ paddingTop: '10px' }}>
                                <TextField
                                    id="dateModified"
                                    label="Date Modified"
                                    type="date"
                                    // onChange={(e) => console.log(e.target.value)}
                                    value={`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() <= 9 ? '0' + today.getDate() : today.getDate()}`}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    InputProps={{
                                        className: classes.input,
                                    }}
                                    placeholder={'yyyy-mm-dd'}
                                    className={classes.textField}
                                    disabled={true}
                                    size="small"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Box sx={{paddingTop: '175px', marginLeft: '10px', marginRight: '10px'}}>
                <BenefitSelections reloPolicy={reloPolicy} distance={distance} estimateInfo={estimateInfo} saveEstimate={saveEstimate} exportEstimate={exportEstimate} generateAuth={generateAuth}/>
            </Box>
        </div>
    );
  }
  
  const mapStateToProps = (state) => {
    return {
      authorizationSearchResults: state.transferees.get('authorizationSearchResults'),
      estimates: state.estimator.get('estimates'),
    };
  };
  
  export default withRouter(
    connect(mapStateToProps, {
      setPageTitle,
      showToast,
      setEstimations
    })(CostEstimatorPage),
  );
  