/* eslint-disable array-callback-return */
import { Box, Typography } from '@material-ui/core';
import React, { Fragment } from 'react';
import { CostUnit, displayCost } from 'utilities/currencyFormatter';

const SelectionsCostBar = (props) => {
    let total = 0;
    props.benefits.filter((b) => props.savedBenefits.includes(b.id)).map((benefit) => {
        benefit.options.map((opt) => {
            if (opt.variable) {
                total = total + (props.distance * opt.multiplier);
            } else {
                total = total + opt.cost;
            }
        })
    });

    return (
      <Fragment>
        <Box position="relative" width="100%">
            <Typography sx={{ color: '#4d8766'}} color="primary" variant="h5">
                Estimate: {displayCost(total, CostUnit.CURRENCY)}
            </Typography>
        </Box>
      </Fragment>
    );
  };

export default SelectionsCostBar;


