import Add from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import Paper from '@material-ui/core/Paper';
import React, { Component } from 'react';
import TableCell from '@material-ui/core/TableCell';

import { EditCommandCell } from '../../common/tableEditCommandCell';
import { dateFormatter } from '../../utilities/dateFormatter';

import {
  DataTypeProvider,
  EditingState,
  IntegratedPaging,
  IntegratedSorting,
  PagingState,
  SortingState,
} from '@devexpress/dx-react-grid';

import {
  Grid,
  PagingPanel,
  Table,
  TableEditColumn,
  TableHeaderRow,
} from '@devexpress/dx-react-grid-material-ui';
import { setPageTitle } from 'modules/layout/layout.actions';
import { connect } from 'react-redux';

import { withRouter } from 'react-router-dom';

const DIALOG_CANCEL = 'DIALOG_CANCEL';

const DateTypeProvider = (props) => (
  <DataTypeProvider formatterComponent={dateFormatter} {...props} />
);

const getRowId = (row) => row.AuthorizationID;

class Estimates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'estimateId', title: 'Estimate Id' },
        { name: 'transfereeName', title: 'Transferee Name' },
        { name: 'origin', title: 'Origin Location', getCellValue: (row) => (<div>{row.origin && row.origin.formattedAddress ? row.origin.formattedAddress : ''}</div>) },
        { name: 'destination', title: 'Destination Location', getCellValue: (row) => (<div>{row.destination && row.destination.formattedAddress ? row.destination.formattedAddress : ''}</div>) },
        { name: 'isExecutive', title: 'Is Executive', getCellValue: (row) => (<div>{row.isExecutive ? 'Yes' : 'No'}</div>) },
        { name: 'reloPolicy', title: 'Relo Policy' },
      ],
      tableColumnExtensions: [{  }],
      rows: [],
      currentPage: 0,
      pageSize: 5,
      pageSizes: [5, 10, 15],
      dateColumns: ['LastUpdated'],
      vipColumns: ['VIP'],
      sorting: [{ columnName: 'LastUpdated', direction: 'desc' }],
      modal: {
        modalConfirmOpen: false,
        contentText: '',
        type: null,
        selectedRow: null,
      },
    };

    this.changeSorting = (sorting) => this.setState({ sorting });

    this.TableRow = ({ row, handleEditAuthorization, ...restProps }) => {
      return (
        <Table.Row
          {...restProps}
          onClick={() => this.props.history.push(`/estimates/${row.estimateId}`)}
          style={{
            cursor: 'pointer',
          }}
        />
      );
    };

    this.changeCurrentPage = (currentPage) => this.setState({ currentPage });
    this.changePageSize = (pageSize) => this.setState({ pageSize });
    this.commitChanges = this.commitChanges.bind(this);
    this.cancelAuth = this.cancelAuth.bind(this);
    this.handleModalResponse = this.handleModalResponse.bind(this);
  }

  cancelAuth(row) {
    this.setState({
      modal: {
        modalConfirmOpen: true,
        contentText: 'Cancel This Authorization?',
        type: DIALOG_CANCEL,
        selectedRow: row,
        title: 'Cancel Authorization',
      },
    });
  }

  // required for editing state
  commitChanges() {}

  handleModalResponse(response, type, row) {
    switch (type) {
      case DIALOG_CANCEL:
        if (response) {
          // do cancel
          this.props.handleCancelAuthorization(row.row.AuthorizationID);
        } else {
          // do nothing
        }
        break;

      default:
        break;
    }
    this.setState({ modal: { modalConfirmOpen: false } });
  }

  actionsCell = (props) => {
    return (
      <TableCell>
        <EditCommandCell
          id="cancel"
          text="Delete"
          style={{ border: 'none' }}
          onExecute={this.cancelAuth}
          tableRow={props.tableRow}
        >
          {React.Children.toArray(props.children)}
        </EditCommandCell>
      </TableCell>
    );
  };

  componentDidMount() {
    this.props.setPageTitle('My Estimates');
  }

  render() {
    const { estimates } = this.props;
    const {
      columns,
      pageSize,
      pageSizes,
      currentPage,
      dateColumns,
      sorting,
      tableColumnExtensions,
    } = this.state;

    return (
      <Box sx={{padding: '2rem'}}>

        <Button
            variant="contained"
            size="small"
            color="primary"
            onClick={() => this.props.history.push(`/estimates/new`)}
            style={{ float: 'right', marginTop: '1rem', marginRight: '1rem' }}
        >
            <Add />
            <Hidden mdUp>New</Hidden>
            <Hidden smDown>Add New</Hidden>
        </Button>

        <Paper style={{ padding: '0.5rem' }}>
          <div style={{ padding: '0.3rem', fontSize: '1.25rem' }}>
            Saved Estimates
          </div>
          <Grid rows={estimates} columns={columns} getRowId={getRowId}>
            <DateTypeProvider for={dateColumns} />
            <EditingState
              onEditingRowIdsChange={this.changeEditingRowIds}
              onCommitChanges={this.commitChanges}
            />
            <SortingState
              sorting={sorting}
              onSortingChange={this.changeSorting}
            />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={this.changePageSize}
            />
            <IntegratedSorting />
            <IntegratedPaging />
            <Table
              rowComponent={this.TableRow}
              columnExtensions={tableColumnExtensions}
            />
            <TableHeaderRow showSortingControls />
            <TableEditColumn
              style={{ marginRight: '2rem' }}
              cellComponent={this.actionsCell}
            />
            {estimates && estimates.length > 5 && <PagingPanel pageSizes={pageSizes} />}
          </Grid>
        </Paper>
      </Box>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      estimates: state.estimator.get('estimates'),
    };
};
  
export default withRouter(connect(mapStateToProps, {
    setPageTitle,
})(Estimates));
